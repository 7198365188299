.esTableContainer {
  width: 100%;
  overflow: auto;
}
.esTable {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-collapse: separate;
  border-bottom: 0;
  overflow: hidden;
}
.esTable .esTableRow td {
  white-space: pre-line;
  padding: 54px 12px 24px 12px;
  border-left: none;
  border-top: none;
}

.esTable thead {
  background-color: #fafafa;
}
.esTable .esTableRow th {
  padding: 32px 12px 24px 12px;
  border-left: none;
  border-top: none;
  border-width: 1px;
  min-width: 180px;
}
.esTable .esTableRow th:last-child,
.esTable .esTableRow td:last-child {
  border-right: none;
}

.esTable .esTableRow td:first-child {
  font-weight: 600;
}
.esTable thead tr {
  border-radius: 10px !important;
}
