.error-boundary {
	 min-height: 100vh;
	 height: 100vh;
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	 align-items: center;
	 color: #444;
	 position: relative;
}
 .error-boundary__heading {
	 font-size: 2.5rem;
	 font-weight: 400 !important;
	 margin: 2rem 0 !important;
}
 .error-boundary__emoji {
	 font-size: 6rem;
}
 .error-boundary__message {
	 font-size: 1.5rem;
}
 .error-boundary__help {
	 font-size: 1.5rem;
	 position: absolute;
	 bottom: 10vh;
	 transform: translateX(50%, 50%);
}
 