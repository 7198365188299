.col-split-bot .split-item {
  display: block;
  box-sizing: border-box;
}
.col-split-bot .split-item:not(:first-child) {
  margin-top: 2em;
}
.color-picker {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.color-picker__input {
  width: 10rem !important;
}
.color-picker__label {
  margin-left: 2rem;
}
.color-picker__label pre {
  margin-bottom: 0;
}
.color-picker__label code {
  /* background-color: transparent; */
  color: #333;
}
.font-md {
  font-size: 1.69rem !important;
}
.summary-table-box-md {
  box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.1), 0 0px 20px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd !important;
  border-radius: 15px !important;
  background-color: #fff;
  display: block !important;
  /* min-height: 350px;
	 */
}
.summary-table-box-md table tr th {
  border-bottom: 0px !important;
  padding: 25px 17px !important;
}
.summary-table-box-md table tr td {
  padding: 20px !important;
}
.text-italic {
  font-style: italic;
}
.vh-min {
  min-height: 100vh;
}
.bg-gray tr th {
  background-color: #efefef;
}
.file_progress {
  border-radius: 7px;
  overflow: hidden;
  width: auto;
  height: 40px;
  color: #00a4ff !important;
  display: table;
  min-width: 15rem;
}
.file_progress__placeholder {
  background-color: #e5f6ff;
  padding: 10px 20px;
}
.file_progress__mark {
  display: block;
  width: 0;
  background-color: #00a4ff;
  margin-top: 1px;
  height: 0.5rem;
  transition: width 0.3s ease;
  backface-visibility: visible;
  opacity: 0;
}
.color-black {
  color: #333 !important;
}
.message-viewport {
  max-height: 28rem;
  overflow-y: auto;
}
.number_counts {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.number_counts .number_counts__type:not(:first-child) {
  margin-left: 2rem;
}
.number_counts .number_counts__type {
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.terms-header::marker {
  font-size: 24px;
  font-weight: 500;
}
.btn_refresh {
  margin-top: 2px;
  position: absolute;
  right: 14%;
  top: 2px;
}
.refresh-loader {
  animation: rotate 0.5s infinite linear;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.ml-sm {
  margin-left: 2rem;
}
.ox-hide {
  overflow-x: hidden;
}
.border-all-sides {
  border: 1px solid #ddd !important;
}
.cursor-disabled {
  cursor: not-allowed !important;
}
.d-flex {
  display: flex;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-right {
  justify-content: flex-end;
}
.flex-align-items-center {
  align-items: center;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.flex-align-items-c {
  align-items: center;
}
.flex-align-content-c {
  align-content: center;
}

input[type="color"]:disabled {
  cursor: not-allowed !important;
}
.textarea-purple {
  background-color: rgba(159, 102, 147, 0.1) !important;
  border: 1px solid #5f004b !important;
}
.btn-purple-sm {
  padding: 10px 23px;
  font-size: 14px !important;
  background-color: #9f6693;
  color: white;
}
.select-d:disabled {
  height: 42px !important;
}
.history-table-box {
  border: 1px solid #ddd !important;
  border-radius: 15px !important;
  background-color: #fff;
  display: block !important;
  overflow: hidden;
}
.history-table-box table tr th {
  border-bottom: 0px !important;
  padding: 3rem !important;
}
.history-table-box table tr td {
  padding: 2.5rem !important;
}
.history-table-box table tr .padding-table-15 {
  padding: 2rem 1rem !important;
}

.carrier-orange label {
  padding: 22px 15px 15px 15px;
  margin: 5px 0;
  background-color: #fff;
  border-radius: 9px;
  border: 1px solid #fff;

  display: table !important;
  width: 100% !important;
  font-weight: normal !important;
}
.carrier-orange label:hover {
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #f7b500;
}
.carrier-orange input[type="checkbox"] {
  position: relative;
  top: 1px;
  border-radius: 50px !important;
  border: 1px solid #ccc;
}
.carrier-orange .isSelected {
  background-color: #fff !important;
  border: 1.2px solid #f7b500;
}

.carrier-orange input[type="checkbox"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
}

.carrier-orange input[type="checkbox"]:before {
  font-family: "FontAwesome";
  content: "\f12a";
  font-size: 15px;
  color: transparent !important;
  background: #fff;
  display: block;
  width: 22px;
  height: 22px;
  border: 1px solid ccc;
  text-align: center !important;
  padding: 3px !important;
  border-radius: 50px !important;
}

.carrier-orange input[type="checkbox"]:checked:before {
  background: #f7b500;
  color: #fff !important;
}

.carrier-orange input[type="checkbox"]:focus {
  outline: 0px !important;
}

.carrier-orange img {
  width: 50px;
}
.ws-12 {
  word-spacing: 12px;
}
.MultiCarousel {
  float: left;
  overflow: hidden;
  padding: 10px 40px 10px 40px;
  width: 100%;
  position: relative;
}
.MultiCarousel .MultiCarousel-inner {
  transition: 1s ease all;
  float: left;
  min-height: 280px !important;
}

.MultiCarousel .MultiCarousel-inner {
  transition: 1s ease all;
  float: left;
  min-height: 280px !important;
}
.MultiCarousel .MultiCarousel-inner .item {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}
.MultiCarousel .MultiCarousel-inner .item > div {
  text-align: center;
  padding: 0px;
  margin: 0px;
  background: #ffffff;

  border-radius: 7px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.14);
  min-height: 285px;
  height: auto;
}

.MultiCarousel .leftLst,
.MultiCarousel .rightLst {
  /* z-index: 10000 !important; */
  position: absolute;
  border-radius: 50%;
  top: calc(43% - 20px);
  color: #fff;
  background-color: rgb(159, 102, 147);
}
.MultiCarousel .leftLst {
  left: 0;
}
.MultiCarousel .rightLst {
  right: 0;
}

.MultiCarousel .leftLst.over,
.MultiCarousel .rightLst.over {
  pointer-events: none;
  background-color: rgb(159, 102, 147, 0.5);
}
.scroll-prv-btn {
  padding: 6px 15px !important;
  border-radius: 100%;
  border: 0px !important;
  margin-left: 3px !important;
  outline: none !important;
}
.scroll-next-btn {
  padding: 6px 15px !important;
  border-radius: 100%;
  border: 0px !important;
  margin-right: 3px !important;
  outline: none !important;
}
.__select-1 .PhoneInputCountry {
  height: 42px !important;
}
.ipCode {
  background-color: #ededed;
  padding: 2px 4px;
  margin: 0 2px;
}
.launch-status-box {
  /* background-color: #ffffff; */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0;
  display: table;
  width: 100%;
  min-height: 220px;
  background: linear-gradient(90deg, #ffffff 64.6%, #fde7e9 114.75%);
}
@media screen and (max-device-width: 800px) {
  .launch-status-box {
    background: #ffffff;
  }
}
.launch-status-box__status {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  position: relative;
}

.launch-status-box__img {
  position: absolute;
  right: 5%;
  top: 20%;
}

@media screen and (max-device-width: 800px) {
  .launch-status-box .launch-status-box__img {
    display: none;
  }
}
.text-align-left {
  text-align: left !important;
}
.label-inactive {
  background-color: #ccc;
}
.label-active {
  background-color: #9f6693;
}
.break-word-overflow {
  word-break: break-all;
}
.d-flex-ip-comp {
  display: flex;
  align-items: center;
}
.carrier-rbm-outer-box {
  padding: 25px;
  background-color: #fff !important;
  display: block !important;
  border: 1px solid #d5d6d9;
  border-radius: 8px;
  margin: 15px;
}

.carrier-rbm-box {
  padding: 15px;
  border-radius: 8px;
  background-color: #fff !important;
  display: block !important;
  border: 1px solid #e1e1e1;
}

.carrier-rbm-box .carrier-rbm label {
  padding: 5px 5px 15px 5px !important;
}

.carrier-rbm-box hr {
  margin: 5px 0px 15px 0px;
}

.carrier-rbm-label-padding label {
  padding: 15px 15px 15px 15px;
}

.carrierbox-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}
.p-0 {
  padding: 0 !important;
}
.m-0 {
  margin: 0 !important;
}

.fw-7 {
  font-weight: 700 !important;
}
.font-roboto {
  font-family: Roboto, sans-serif;
}
.desc-lg {
  white-space: pre-wrap;
  word-break: break-all;
}
.btn-yellow-round {
  border: 1px #ffc600 solid !important;
  border-radius: 50px !important;
  padding: 10px 25px !important;
  color: #000000 !important;
  background-color: #ffc600 !important;
  transition: all 0.3s ease-out;
}
.btn-yellow-round:hover,
.btn-yellow-round:focus,
.btn-yellow-round:active {
  border: 1px #f1be04 solid !important;
  background-color: #f1be04 !important;
}
